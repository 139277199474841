import { Link } from '~components'
import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap'
import LogoBlack from '~image/logo/logo-black.png'
import Footer from "./style"
import contact from "~data-yaml/contact.yml"
import links from "~data-yaml/menudata.yml"
import axios from "axios"

const FooterSection = () => {
    const [email1, setEmail1] = useState('')

    const send = async (f) => {
        f.preventDefault();

        axios.post('https://1rlckjb3w6.execute-api.us-east-1.amazonaws.com/prod/email', {
            email: email1,
            newsletter: "1"
        }).then((res) => {
            //console.log(res)
            document.getElementById("message2").style.color = "blue";
            document.getElementById("message2").innerHTML = "Thank you!";
        }).catch(function(error) {
            document.getElementById("message2").style.color = "red";
            document.getElementById("message2").innerHTML = JSON.stringify(error);
        }).finally(() => {
            f.target.reset()
        });
    }


    let today = new Date()
    const year = today.getFullYear();
    return (
        <Footer>
            <Container>
                <Footer.Box pbXL="95px">
                    <Row>
                        <Col xs="12" className="col-lg-3 col-md-7 col-xs-9">
                            <Footer.Widgets className="footer-widgets footer-widgets--l7">
                                {/* Brand Logo*/}
                                <Footer.Box mb="30px">
                                    <Link to="#">
                                        <img src={LogoBlack} alt="logo" />
                                    </Link>
                                </Footer.Box>
                                <Footer.Text mb="36px">
                                    We’re a consulting firm that provides<br className="d-none d-xl-block" /> technology services and solutions.<br className="d-none d-xl-block" /></Footer.Text>
                                <Footer.SocialShare>
                                    {contact.social_share.map((item, i) => {
                                        return (
                                            <Footer.SocialShareItem key={i}>
                                                <a href={item.link}>
                                                    <i className={item.icon} />
                                                </a>
                                            </Footer.SocialShareItem>
                                        )
                                    })}
                                </Footer.SocialShare>
                            </Footer.Widgets>
                        </Col>
                        <Col xs="12" className="col-lg-9">
                            <Row>
                                <Col xs="12" className="col-lg-4 col-xs-6">
                                    <Footer.Widgets>
                                        <Footer.Title>Contact Details</Footer.Title>
                                        <Footer.Address className="widgets-address">
                                            <Footer.AddressItem>
                                                <i className="fa fa-map-marker-alt" />
                                                <span>Address: <br className="d-block" />{contact.address}</span>
                                            </Footer.AddressItem>
                                            <Footer.AddressItem>
                                                <i className="fa fa-phone-alt" />

                                                <a href="#"><span> Phone: </span><br className="d-block" /> {contact.phone}</a>
                                            </Footer.AddressItem>
                                            <Footer.AddressItem>
                                                <i className="fa fa-envelope" />
                                                <a href="mailto:info@medcartel.com">
                                                    <span className="d-block"> Email:
                                                    </span>{contact.email}</a>
                                            </Footer.AddressItem>
                                        </Footer.Address>
                                    </Footer.Widgets>
                                </Col>
                                <Col xs="12" className="col-lg-4 col-xs-6">
                                    <Footer.Widgets>
                                        <Footer.Title>Company</Footer.Title>
                                        <Footer.List>
                                        {links.footer_links.map((ref,i) => {
                                            return (
                                                <Footer.ListItems key={i}>
                                                <a href={"/"+ref.name} key={"fl"+i}>{ref.label}</a>
                                            </Footer.ListItems>
                                            )
                                        })}
                                        </Footer.List>
                                    </Footer.Widgets>
                                </Col>
                                <Col xs="12" className="col-lg-4 col-md-6 col-xs-9">
                                    <Footer.Widgets>
                                        <Footer.Title>Newsletter</Footer.Title>
                                        <Footer.Text>Get the latest trends updates right at your inbox.</Footer.Text>
                                        {/* Newsletter */}
                                        <Footer.Newsletter>
                                            <form method="post" onSubmit={(f) => send(f)}>
                                                <input type="text" placeholder="Enter Your Email" required  onChange={(f) => setEmail1(f.target.value)}/>
                                                <Footer.NewsletterBtn backgroundColor="#ff5200" hoverTextColor="#fff" textColor="#fff" sizeX="150px" sizeY="56px" type="submit">Subscribe</Footer.NewsletterBtn>
                                                <div className="form-check d-flex align-items-center">
                                                    <label className="form-check-label" htmlFor="flexCheckDefault" id="message2">
                                                    </label>
                                                </div>
                                            </form>
                                        </Footer.Newsletter>
                                        {/*/ .Newsletter */}
                                    </Footer.Widgets>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Footer.Box>
                <Footer.Copyright>
                    <Footer.CopyrightText>© {year} Sounetu. All Rights Reserved</Footer.CopyrightText>
                </Footer.Copyright>
            </Container>
        </Footer>
    )
}
export default FooterSection